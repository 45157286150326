<template>
  <CRow>
    <CCol :md="6">
      <CInputGroup size="sm" class="mb-3">
        <CInputGroupText id="inputGroup-sizing-sm">Cerca Lega</CInputGroupText>
        <input v-model="searchL" />
      </CInputGroup>
      <CCard class="mb-4" :class="'h-700'">
        <CCardBody>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                <CTableHeaderCell scope="col">Nome</CTableHeaderCell>
                <CTableHeaderCell scope="col"></CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow v-for="(league, index) in searchedVals" :key="index">
                <CTableHeaderCell scope="row" v-if="!allowed.includes(league)">{{
                  index
                }}</CTableHeaderCell>
                <CTableDataCell v-if="!allowed.includes(league)">{{
                  league
                }}</CTableDataCell>
                <CTableDataCell v-if="!allowed.includes(league)"
                  ><button
                    class="btn btn-success"
                    @click="allow(league)"
                    v-if="!allowed.includes(league)"
                  >
                    Aggiungi
                  </button></CTableDataCell
                >
              </CTableRow>
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :md="6">
      <CCard class="mb-4" :class="'h-700'">
        <CCardBody>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                <CTableHeaderCell scope="col">Nome</CTableHeaderCell>
                <CTableHeaderCell scope="col"></CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow v-for="(leagueA, indexA) in allowed" :key="indexA">
                <CTableHeaderCell scope="row">{{ indexA }}</CTableHeaderCell>
                <CTableDataCell>{{ leagueA }}</CTableDataCell>
                <CTableDataCell
                  ><button class="btn btn-danger" @click="disallow(indexA)">
                    Rimuovi
                  </button>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getLeagues } from "../../plugins/leagues";
import { getData, postData } from "../../plugins/fetch";
import { watch, ref } from "vue-demi"
import Fuse from "fuse.js";

export default {
  name: "InsertLeague",
  setup() {
    const leagues = getLeagues();
    const searchL = ref("");
    const searchedVals = ref(leagues);
    watch(searchL, (val) => {
      const f_search = new Fuse(leagues, {includeScore: true});
      const result = f_search.search(val);
      if(val != ""){
        searchedVals.value = result.map((league) => league.item);
      } else {
        searchedVals.value = leagues;
      }
    })
    return {
      leagues,
      searchL,
      searchedVals
    };
  },
  data() {
    return {
      allowed: []
    };
  },
  methods: {
    allow(name) {
      this.allowed.push(name);
      postData("https://api.soccerstandings.online/insert-league", { league: name });
    },
    disallow(index) {
      console.log(this.allowed[index]);
      postData("https://api.soccerstandings.online/remove-league", {
        league: this.allowed[index]
      });
      this.allowed.splice(index, 1);
    },
  },
  mounted() {
    getData("https://api.soccerstandings.online/get-allowed").then((allowedjar) => {
      this.allowed = allowedjar;
    });
  },
};
</script>

<style>
.h-700 {
  height: 700px;
  overflow-y: auto;
}
</style>
